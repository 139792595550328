import { DEFAULT_STATUS_KEY, StatusInfo } from 'src/core/StatusInfo';

export const LoanStatusInfoMap: Record<string, StatusInfo> = {
    PENDING: {
        color: 'var(--pending-color)',
        borderColor: 'var(--pending-border-color)',
        title: 'Pending',
        backgroundColor: 'var(--pending-background)',
    },
    ACTIVE: {
        color: 'var(--active-color)',
        borderColor: 'var(--active-border-color)',
        title: 'Active',
        backgroundColor: 'var(--active-background)',
    },
    REPAID: {
        color: 'var(--repaid-color)',
        borderColor: 'var(--repaid-border-color)',
        title: 'Repaid',
        backgroundColor: 'var(--repaid-background)',
    },
    OVERDUE: {
        color: 'var(--overdue-color)',
        borderColor: 'var(--overdue-border-color)',
        title: 'Overdue',
        backgroundColor: 'var(--overdue-background)',
    },
    WRITTEN_OFF: {
        color: 'var(--writtenOff-color)',
        borderColor: 'var(--writtenOff-border-color)',
        title: 'Written-off',
        backgroundColor: 'var(--writtenOff-background)',
    },
    ERROR: {
        color: 'var(--error-color)',
        borderColor: 'var(--error-border-color)',
        title: 'Error',
        backgroundColor: 'var(--error-background)',
    },
    [DEFAULT_STATUS_KEY]: {
        color: '#91796c',
        borderColor: '#F4F0DF',
        title: '',
        backgroundColor: '#F4F0DF',
    },
};
