/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Formio, Templates, Utils, Widgets } from 'formiojs';
import AllComponents from 'formiojs/components';
import Components from 'formiojs/components/Components';
import { customComponents } from 'hes-formio-components';
import AddressField from 'src/formio/components/Address/Address';
import Datagrid from 'src/formio/components/Datagrid/Datagrid';
import Datetime from 'src/formio/components/Datetime/Datetime';
import File from 'src/formio/components/FileComponent/FileComponent';
import FomRenderer from 'src/formio/components/FomRendererComponent/FomRendererComponent';
import NumberField from 'src/formio/components/NumberField/NumberField';
import PhoneField from 'src/formio/components/PhoneField/PhoneField';
import Select from 'src/formio/components/Select/Select';
import TextField from 'src/formio/components/TextField/TextField';
import { formioBaseComponentCustomTemplate } from 'src/formio/templates/base';
import { formioDropdownComponentCustomTemplate } from 'src/formio/templates/dropdownComponent';
import { formioFileComponentCustomTemplate } from 'src/formio/templates/file';
import { formioInputComponentCustomTemplate } from 'src/formio/templates/input';
import { formioPaginationComponentCustomTemplate } from 'src/formio/templates/paginationComponent';
import CalendarWidget from 'src/formio/Widgets/CalendarWidget';
import { convertToFormioFileModels } from 'src/utils/formio/convertToFormioFileModels';
import { getCurrentUser } from 'src/utils/formio/getCurrentUser';

// @ts-ignore No other way to redefine calendar
Widgets.calendar = CalendarWidget;

Components.setComponents({
    ...AllComponents,
    ...customComponents,
    ...{
        select: Select,
        textfield: TextField,
        number: NumberField,
        datetime: Datetime,
        address: AddressField,
        file: File,
        datagrid: Datagrid,
        phoneNumber: PhoneField,
        formRendererComponent: FomRenderer,
    },
});
Formio.setBaseUrl(`${window.location.protocol}//${window.location.host}`);

// Extends Formio utils
(Utils as any).getCurrentUser = getCurrentUser;
(Utils as any).convertToFormioFileModels = convertToFormioFileModels;

Templates.current = {
    input: {
        form: formioInputComponentCustomTemplate,
    },
    file: {
        form: formioFileComponentCustomTemplate,
    },
    dropDownTemplate: {
        form: formioDropdownComponentCustomTemplate,
    },
    component: {
        form: formioBaseComponentCustomTemplate,
    },
    paginationTemplate: {
        form: formioPaginationComponentCustomTemplate,
    },
};
