import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { BasePage, PageType } from 'src/components/BasePage';
import { Spinner } from 'src/components/Spinner/Spinner';
import { SplashScreen } from 'src/components/SplashScreen/SplashScreen';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { TaskRepresentation } from 'src/generated-api-client';
import { AssignedTasksStore } from 'src/stores/AssignedTasksStore/AssignedTasksStore';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

type TaskByKeyPageRouterParams = {
    taskKey: string;
};

export const TaskByKeyPage = observer(() => {
    const { params } = useRouteMatch<TaskByKeyPageRouterParams>();
    const searchQueries = Router.getQuerySearchParams();

    const { isLoading } = AssignedTasksStore.listLoader;

    const fetchData = async () => {
        await AssignedTasksStore.loadList();
        const taskDefinition = AssignedTasksStore.list.find(
            (taskDefinition: TaskRepresentation) =>
                (taskDefinition?.taskInfo as unknown as { key: string })
                    ?.key === params.taskKey,
        );
        openTaskPage(taskDefinition?.id || '');
    };

    useEffect(() => {
        fetchData();
    }, [params.taskKey]);

    const openTaskPage = (taskId: string) => {
        if (taskId) {
            const taskNavigationUrl = UrlHelper.getTargetUrl(RoutePaths.task, {
                taskId: taskId!,
            });

            Router.navigate({
                pathname: taskNavigationUrl,
                search: searchQueries,
            });
        } else {
            Router.navigate(RoutePaths.index);
        }
    };

    if (isLoading) {
        return <StyledSpinner />;
    }

    return (
        <BasePage pageName="taskAction" type={PageType.SIMPLE_FORM}>
            <SplashScreen />
        </BasePage>
    );
});

const StyledSpinner = styled(Spinner)`
    margin: 0 auto;
    display: block;
`;
